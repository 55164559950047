@mixin accessible-focus-state() {
	@apply outline-none bg-focus-light no-underline hover:no-underline hover:bg-focus-light group-hover:bg-focus-light;
	@apply text-focus-dark #{!important};

	&:not(.no-path-fill) {
		path {
			@apply fill-focus-dark #{!important};
		}
	}
}

a,
button,
.focus-visible {
	&:focus-visible {
  	@include accessible-focus-state();
  	@apply shadow-[0_-3px_0_0_theme(colors.focus.light),0_1px_0_0_theme(colors.focus.light),0_4px_0_0_theme(colors.focus.dark)];
	}
}

.button,
form button,
form [type=button],
form [type=submit] {
	&:focus-visible:not(.custom-button):not(.iti__selected-country) {
  	@include accessible-focus-state();
  	@apply border-focus-light hover:border-focus-light shadow-[0_0_0_4px_theme(colors.focus.dark)];
	}
}

/* Fallback for browsers without :focus-visible support */
@supports not selector(:focus-visible) {
  a,
  button
	.focus-visible {
		&:focus {
    	@include accessible-focus-state();
    	@apply shadow-[0_-3px_0_0_theme(colors.focus.light),0_1px_0_0_theme(colors.focus.light),0_4px_0_0_theme(colors.focus.dark)];
		}
  }
}

.visually-hidden {
  @apply absolute -left-[9999px] top-auto w-[1px] h-[1px] overflow-hidden;
}

@media (prefers-reduced-motion: reduce) {
  * {
    @apply animate-none #{!important};
    @apply transition-none #{!important};
  }
}

.skip-link:focus-visible {
  @include accessible-focus-state();
  @apply focus-visible:shadow-[0_-3px_0_0_theme(colors.focus.light),0_1px_0_0_theme(colors.focus.light),0_4px_0_0_theme(colors.focus.dark)];
  @apply fixed top-[1px] left-[1px] w-auto h-auto z-[99];
}

input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
select,
textarea,
input[type=checkbox],
input[type=radio] {
  &:focus-visible {
    @apply outline-none border-focus-dark shadow-[0_0_0_4px_theme(colors.focus.light),inset_0_0_0_2px_theme(colors.focus.dark)];
  }
}