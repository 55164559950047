/* There are a bunch of Entries that have been migrated from WordPress (namely blog and news posts)
	These are just blobs of HTML, and so because we are using Tailwind, don't get any styling
	applied to them as they have no utility classes.
*/

.legacy-body-text {
	h1 {
		@extend .heading-xxl;
	}

	h2 {
		@extend .heading-xl;
		@apply mt-7 mb-5 sm:mt-7-sm sm:mb-5-sm;
	}

	h3 {
		@extend .heading-l;
		@apply mt-5 mb-4 sm:mt-5-sm sm:mb-4-sm;
	}

	h4 {
		@extend .heading-s;
		@apply mt-5 mb-4 sm:mt-5-sm sm:mb-4-sm;
	}

	.pull-quote {
		@apply py-3 pl-[1.2rem] my-5 border-l-4 body-s border-base-mid-ochre max-w-reading-width sm:my-5-sm sm:py-3-sm sm:pl-[2rem];
	}
}