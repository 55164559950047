#ot-sdk-cookie-policy.ot-sdk-cookie-policy--q-community {
	font-size: inherit;
	@apply max-w-reading-width;

	#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy {
		font-size: inherit;

		section {
			margin: 0;
		}

		h5,
		h6,
		li,
		p,
		a,
		span,
		th,
		td,
		#cookie-policy-description,
		#cookie-policy-title,
		.ot-sdk-cookie-policy-group {
			color: inherit;
			@apply antialiased;
		}

		.ot-table-header {
			font-size: inherit;
		}

		.ot-sdk-cookie-policy-title {
			@apply heading-xl mt-7 mb-5 sm:mt-7-sm sm:mb-5-sm;
		}

		#cookie-policy-description, p {
			@apply serif-s mb-4 sm:mb-4-sm;
		}

		.ot-sdk-cookie-policy-group {
			@apply heading-l mt-5 mb-4 sm:mt-5-sm sm:mb-4-sm;
		}

		a {
			background-color: transparent; // Just why
			@apply text-interactions underline decoration-1 underline-offset-2 hover:no-underline hover:text-interactions-hover;
		}

		table {
			@apply body-s;

			thead, th {
				background-color: transparent;
			}
		}
	}
}
