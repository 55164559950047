//-----------//
// Fairgates //
//-----------//

// @font-face {
// 	font-family: 'fairgates';
// 	src: 	url('/dist/fonts/fairgates-variable/fairgates-variable.ttf') format('ttf supports variations');
// 	font-weight: 100 1000;
// }

@font-face {
	font-family: 'fairgates';
	src: url('/dist/fonts/fairgates/fairgates-extralight-italic.otf') format('opentype');
	font-display: swap;
	font-style: italic;
	font-weight: 200;
}

@font-face {
	font-family: 'fairgates';
	src: url('/dist/fonts/fairgates/fairgates-extralight.otf') format('opentype');
	font-display: swap;
	font-style: normal;
	font-weight: 200;
}

@font-face {
	font-family: 'fairgates';
	src: url('/dist/fonts/fairgates/fairgates-light-italic.otf') format('opentype');
	font-display: swap;
	font-style: italic;
	font-weight: 300;
}

@font-face {
	font-family: 'fairgates';
	src: url('/dist/fonts/fairgates/fairgates-light.otf') format('opentype');
	font-display: swap;
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: 'fairgates';
	src: url('/dist/fonts/fairgates/fairgates-regular-italic.otf') format('opentype');
	font-display: swap;
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: 'fairgates';
	src: url('/dist/fonts/fairgates/fairgates-regular.otf') format('opentype');
	font-display: swap;
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: 'fairgates';
	src: url('/dist/fonts/fairgates/fairgates-medium-italic.otf') format('opentype');
	font-display: swap;
	font-style: italic;
	font-weight: 500;
}

@font-face {
	font-family: 'fairgates';
	src: url('/dist/fonts/fairgates/fairgates-medium.otf') format('opentype');
	font-display: swap;
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: 'fairgates';
	src: url('/dist/fonts/fairgates/fairgates-semibold-italic.otf') format('opentype');
	font-display: swap;
	font-style: italic;
	font-weight: 600;
}

@font-face {
	font-family: 'fairgates';
	src: url('/dist/fonts/fairgates/fairgates-semibold.otf') format('opentype');
	font-display: swap;
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: 'fairgates';
	src: url('/dist/fonts/fairgates/fairgates-bold-italic.otf') format('opentype');
	font-display: swap;
	font-style: italic;
	font-weight: 700;
}

@font-face {
	font-family: 'fairgates';
	src: url('/dist/fonts/fairgates/fairgates-bold.otf') format('opentype');
	font-display: swap;
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: 'fairgates';
	src: url('/dist/fonts/fairgates/fairgates-extrabold-italic.otf') format('opentype');
	font-display: swap;
	font-style: italic;
	font-weight: 800;
}

@font-face {
	font-family: 'fairgates';
	src: url('/dist/fonts/fairgates/fairgates-extrabold.otf') format('opentype');
	font-display: swap;
	font-style: normal;
	font-weight: 800;
}

//------------------//
// Freight Text Pro //
//------------------//

@font-face {
	font-family: 'freight-text-pro';
	src: url('/dist/fonts/freight-text-pro/FreightTextProLight-Italic.otf') format('opentype');
	font-display: swap;
	font-style: italic;
	font-weight: 300;
}

@font-face {
	font-family: 'freight-text-pro';
	src: url('/dist/fonts/freight-text-pro/FreightTextProLight-Regular.otf') format('opentype');
	font-display: swap;
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: 'freight-text-pro';
	src: url('/dist/fonts/freight-text-pro/FreightTextProBook-Italic.otf') format('opentype');
	font-display: swap;
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: 'freight-text-pro';
	src: url('/dist/fonts/freight-text-pro/FreightTextProBook-Regular.otf') format('opentype');
	font-display: swap;
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: 'freight-text-pro';
	src: url('/dist/fonts/freight-text-pro/FreightTextProMedium-Italic.otf') format('opentype');
	font-display: swap;
	font-style: italic;
	font-weight: 500;
}

@font-face {
	font-family: 'freight-text-pro';
	src: url('/dist/fonts/freight-text-pro/FreightTextProMedium-Regular.otf') format('opentype');
	font-display: swap;
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: 'freight-text-pro';
	src: url('/dist/fonts/freight-text-pro/FreightTextProSemibold-Italic.otf') format('opentype');
	font-display: swap;
	font-style: italic;
	font-weight: 600;
}

@font-face {
	font-family: 'freight-text-pro';
	src: url('/dist/fonts/freight-text-pro/FreightTextProSemibold-Regular.otf') format('opentype');
	font-display: swap;
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: 'freight-text-pro';
	src: url('/dist/fonts/freight-text-pro/FreightTextProBold-Italic.otf') format('opentype');
	font-display: swap;
	font-style: italic;
	font-weight: 700;
}

@font-face {
	font-family: 'freight-text-pro';
	src: url('/dist/fonts/freight-text-pro/FreightTextProBold-Regular.otf') format('opentype');
	font-display: swap;
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: 'freight-text-pro';
	src: url('/dist/fonts/freight-text-pro/FreightTextProBlack-Italic.otf') format('opentype');
	font-display: swap;
	font-style: italic;
	font-weight: 900;
}

@font-face {
	font-family: 'freight-text-pro';
	src: url('/dist/fonts/freight-text-pro/FreightTextProBlack-Regular.otf') format('opentype');
	font-display: swap;
	font-style: normal;
	font-weight: 900;
}


@layer base {
	b, strong {
		font-weight: 600;
	}

	p {
		@apply mb-4 sm:mb-4-sm;

		&.body-s {
		}
	}

	.body-s p,
	p.body-s {
		@apply mb-3 sm:mb-3-sm;
	}

	.font-fairgates {
		font-feature-settings: 'ss03' on;
	}

	.heading-xxxl {
		@apply font-fairgates text-heading-xxxl -tracking-1% font-bold sm:text-heading-xxxl-sm sm:-tracking-2%;
	}

	.heading-xxl {
		@apply font-fairgates text-heading-xxl -tracking-1% font-bold sm:text-heading-xxl-sm sm:-tracking-2%;
	}

	.heading-xl {
		@apply font-fairgates text-heading-xl font-bold sm:text-heading-xl-sm sm:-tracking-1%;
	}

	.heading-l {
		@apply font-fairgates text-heading-l font-bold sm:text-heading-l-sm;
	}

	.heading-m {
		@apply font-fairgates text-heading-m font-bold sm:text-heading-m-sm;
	}

	.heading-s {
		@apply font-fairgates text-heading-s font-bold sm:text-heading-s-sm;
	}

	.heading-xs {
		@apply font-fairgates text-heading-xs font-bold sm:text-heading-xs-sm;
	}

	.heading-xxs {
		@apply font-fairgates text-body-xs font-bold sm:text-body-xs-sm;
	}

	.heading-stat {
		@apply text-heading-stat -tracking-4% font-bold sm:text-heading-stat-sm sm:-tracking-4%;
	}

	.body-m {
		@apply font-fairgates text-body-m sm:text-body-m-sm;
	}

	.body-s {
		@apply font-fairgates text-body-s sm:text-body-s-sm;
	}

	.body-xs {
		@apply font-fairgates text-body-xs sm:text-body-xs-sm;
	}

	.body-xxs {
		@apply font-fairgates text-body-xxs sm:text-body-xxs-sm;
	}

	.serif-s {
		@apply text-serif-s font-freight-text-pro sm:text-serif-s-sm;

		strong, a {
			@apply font-fairgates text-serif-s-strong sm:text-serif-s-strong-sm;
		}
	}

	a {
		@apply text-interactions underline decoration-1 underline-offset-2 hover:no-underline hover:text-interactions-hover;
	}

	.group-link {
		@apply text-interactions underline decoration-1 underline-offset-2 group-hover:no-underline group-hover:text-interactions-hover;
		@apply group-focus-visible:text-black group-focus-visible:no-underline;
	}

	.styled-lists {
		ol, ul {
			@apply flex flex-col gap-2;

			li {
				@apply relative pl-[2.2rem] sm:pl-[2.4rem];
			}

			&:not(.mb-0) {
				@apply mb-4 sm:mb-4-sm;
			}
		}

		ol {
			@apply [counter-reset:section];

			li {
				@apply [counter-increment:section];

				&:before {
					@apply [content:counters(section,'')'.'] absolute left-0;
				}
			}
		}

		ul li:before {
			@apply content-[''] rounded-full block bg-text-primary absolute left-1 top-[.8rem] w-[.8rem] h-[.8rem] sm:top-[.9rem] sm:w-[1rem] sm:h-[1rem];
		}
	}
}