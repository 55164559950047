input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  @apply hidden;
}

input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
select,
textarea {
  @apply body-s p-text-input border-2 border-borders-form-inputs w-full rounded-none placeholder-text-secondary bg-surfaces-inputs;
}

select {
	@apply max-w-input relative appearance-none m-0 pr-[4.5rem] cursor-pointer bg-no-repeat bg-[url('../images/icons/functional/dropdown.svg')] bg-[right_center] bg-[length:4.5rem_3.6rem];
}

//------------//
// Checkboxes //
//------------//

.checkbox-wrapper {
	@apply flex items-start mb-2 last:mb-0;
}

.checkbox-wrapper--small {
	@apply pt-[.9rem] pb-[.7rem] mb-0;
}

input[type=checkbox] {
  @apply cursor-pointer appearance-none flex-shrink-0 flex-grow-0 border-2 border-borders-form-inputs bg-white mr-[1.2rem];

	&:checked {
		@apply bg-no-repeat bg-center text-base-black;
	}

	+ label {
		@apply cursor-pointer;
	}
}

input[type=checkbox]:not(.checkbox--small) {
	@apply w-[4rem] h-[4rem];

	&:checked {
		@apply bg-[url('/dist/images/icons/functional/checkbox-checked.svg')] bg-[length:2.8rem_2.8rem];
	}

	+ label {
		@apply pt-[.9rem] pb-[.3rem] sm:pt-[.8rem] sm:pb-[.4rem];
	}
}

input[type=checkbox].checkbox--small {
	@apply w-[2.4rem] h-[2.4rem];

	&:checked {
		@apply bg-[url('/dist/images/icons/functional/checkbox-small-checked.svg')] bg-[length:1.8rem_1.8rem];
	}
}

//---------------//
// Radio buttons //
//---------------//

.radio-wrapper {
	@apply cursor-pointer flex items-start mb-2 last:mb-0;
}

.radios--binary {
	.radio-wrapper {
		@apply m-0;
	}
}

input[type=radio] {
	@apply cursor-pointer w-[4rem] h-[4rem] appearance-none flex-shrink-0 flex-grow-0 border-2 border-borders-form-inputs bg-white rounded-full mr-[1.2rem] relative;

	&:before {
    @apply content-[''] block opacity-0 w-[2rem] h-[2rem] rounded-full bg-base-black absolute top-1/2 -translate-y-1/2 left-0 right-0 mx-auto;
  }

  &:checked:before {
    @apply opacity-100;
  }

	+ label {
		@apply cursor-pointer pt-[.9rem] pb-[.3rem] sm:pt-[.8rem] sm:pb-[.4rem];
	}
}

textarea {
  @apply min-h-[124px];
}

form {
	@apply body-s;

	button,
	[type=button],
	[type=submit] {
		&:not(.custom-button):not(.iti__selected-country) {
			@apply rounded inline-flex items-center font-bold border-2 p-button sm:p-button-sm active:translate-y-[.4rem] text-base-white bg-interactions border-interactions hover:bg-interactions-hover hover:border-interactions-hover active:bg-interactions-press active:border-interactions-press shadow-button active:shadow-none;
		}
	}
}

// Formie
.fui-input-wrapper {
	// @apply mb-5 md:mb-5-sm mt-3;
	@apply mt-3;
}

.fui-legend,
.fui-field .fui-label {
  @apply heading-s;
}

.fui-instructions {
  @apply text-text-secondary mt-1;

	& > *:last-child {
		@apply mb-0;
	}
}

.fui-fieldset {
  @apply flex flex-col;

  &.fui-subfield-fieldset {
    @apply mb-[calc(theme(margin.5)-theme(margin.4))] md:mb-[calc(theme(margin.5-sm)-theme(margin.4-sm))];
  }
}

.fui-checkbox,
.fui-radio {
  @apply flex items-start;
}

.fui-layout-vertical {
	.fui-checkbox,
	.fui-radio {
		@apply mb-2 last:mb-0;
	}
}

.fui-layout-horizontal .fui-layout-wrap {
	@apply gap-[2.4rem];
}

.fui-heading {
  @apply mt-0;
}

.fui-required {
  @apply text-base-red;
}

.fui-error-message {
  @apply text-text-errors mb-2 pl-[2.8rem] md:pl-[3.2rem];
  @apply bg-[url('../images/icons/functional/error.svg')] bg-no-repeat bg-[left_2px] bg-[length:2rem_2rem] md:bg-[length:2.4rem_2.4rem];
}

.fui-field.fui-error {
  @apply border-l-4 border-borders-error pl-[1.6rem] mb-4 md:mb-4-sm;
}

.fui-alert {
  @apply border-4 border-borders-error p-4 mb-7 sm:p-4-sm sm:mb-7-sm;

  p {
    @apply body-s last:mb-0;
  }
}

.fui-type-multi-line-text {
  .fui-input-wrapper {
   //  @apply mb-4 md:mb-4-sm;
   	@apply w-full;
  }

  textarea {
    @apply mb-0;
  }

  .fui-limit-text {
    @apply text-text-secondary;
  }
}

.fui-field.fui-error {
	@apply flex flex-col-reverse;
}

.fui-field {
	@apply mb-5 md:mb-5-sm;
}

.fui-progress-container {
	@apply mt-5 md:mt-7;
}

.fui-progress-container .fui-progress {
	@apply w-full bg-white rounded;
}

.fui-progress-container .fui-progress .fui-progress-bar {
	@apply bg-interactions rounded text-white flex justify-center items-center pt-2 pb-1;
}

.fui-btn-container {
	@apply flex gap-4;
}